input.privacy-toggle {
  position: relative;
  appearance: none;
  outline: none;
  width: 45px;
  height: 22px;
  border: 1px solid #e0e0e0;
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 #f5f5f5;
  transition-duration: 200ms;
  background-color: #f5f5f5;
}
.toggle-switch-title {
  font-family: "Mulish";
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  margin-bottom: 8px;
}
input.privacy-toggle:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 1px;
  width: 20px;
  height: 20px;
  background-color: #000000;
  border-radius: 50%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

input.privacy-toggle:checked {
  border-color: #000000;
  box-shadow: inset 20px 0 0 0 #000000;
  background-color: #000000;
}

.toggle-switch-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: end;
}
input.privacy-toggle:checked:after {
  background-color: #ffffff;
  left: 23px;
  box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}
